export default defineI18nConfig(() => ({
  legacy: false,
  fallbackLocale: "en",
  warnHtmlMessage: false,
  fallbackWarn: false,
  missingWarn: false,
  compilation: {
    strictMessage: false,
    escapeHtml: false,
  },
}))
